import React, { useEffect } from 'react';
import { App } from 'antd';
import { eventPublisher } from 'tds-common-fe';
import { PublisherEvent } from '../../types/eventPublisher';

/*
    Antd removed message's static method https://ant.design/docs/blog/why-not-static
    This is the workaround for it to be used outside the react component.
    To show error message, use eventPublisher.publish
 */
const AntdMessageWrapper: React.FunctionComponent = () => {
    const { message } = App.useApp();

    useEffect(() => {
        eventPublisher.subscribe(PublisherEvent.ANTD_ERROR_MESSAGE, (text: string) => message.error(text));
    }, [message]);

    return null;
};

export default AntdMessageWrapper;
