import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import 'antd/dist/reset.css';
import { PersistGate } from 'redux-persist/integration/react';
import { App as AntdApp } from 'antd';
import './polyfill';
import './index.global.styl';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import reportWebVitals from './reportWebVitals';
import LocalizedIntlProvider from './localization/LocalizedIntlProvider';
import Routes from './components/Routes';
import { store, storagePersistor } from './store';
import config from './config';
import { retrieveConsumerInfo } from './api/headerUtils';
import analytics from './analytics/firebaseAnalytics';
import NotificationProvider from './components/Notification/NotificationProvider';
import logger from './logging/logger';
import AntdMessageWrapper from './components/Message/AntdMessageWrapper';
import routeURLs from './components/Routes/urls';

retrieveConsumerInfo();
analytics.initialize();

if (!location.pathname.includes(routeURLs.STANDALONE_MEASUREMENT)) {
    logger.initialize();
}

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);
dayjs.extend(duration);
dayjs.extend(relativeTime);

if (config.NODE_ENV === 'production') {
    Sentry.init({
        dsn: config.SENTRY_DSN,
        environment: config.APP_ENV,
        ignoreErrors: [
            'ResizeObserver loop limit exceeded',
            'ResizeObserver loop completed with undelivered notifications.',
            'Request aborted',
            'Request failed with status code 400',
            'timeout of 30000ms exceeded',
            'Non-Error exception captured with keys: currentTarget, isTrusted, target, type',
        ],
        release: config.APP_VERSION,
    });
}

if (config.APP_ENV !== 'production') {
    // Add meta[name="robots"] to index.html to prevent search engine indexing
    const meta = document.createElement('meta');
    meta.name = 'robots';
    meta.content = 'noindex nofollow';
    document.head.appendChild(meta);
}

const queryClient = new QueryClient({ defaultOptions: { queries: { retry: false } } });

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate persistor={storagePersistor}>
                <LocalizedIntlProvider>
                    <QueryClientProvider client={queryClient}>
                        <NotificationProvider>
                            <AntdApp style={{ height: '100%' }}>
                                <AntdMessageWrapper />
                                <Routes />
                            </AntdApp>
                        </NotificationProvider>
                        <ReactQueryDevtools initialIsOpen={false} />
                    </QueryClientProvider>
                </LocalizedIntlProvider>
            </PersistGate>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
