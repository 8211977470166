import { eventPublisher } from 'tds-common-fe';
import { intlProxy } from '../localization/IntlProxy';
import routeURLs from '../components/Routes/urls';
import { browserHistory, storeDispatch } from '../store';
import postMessenger from '../utils/PostMessenger';
import { cleanUpCredentials } from '../utils/authUtils';
import { getApiURL } from './ApiURL';
import { PublisherEvent } from '../types/eventPublisher';

export interface APIError extends Error {
    response?: {
        status: number;
        data: {
            code: number | string;
            message: string;
            devMessage: string;
            errors?: any;
        };
        request: {
            responseURL: string;
        };
    };
}

function showError(errorMessage: string) {
    if (postMessenger.parentOrigin) {
        postMessenger.postPopupMessage('error', errorMessage);
    } else {
        eventPublisher.publish(PublisherEvent.ANTD_ERROR_MESSAGE, errorMessage);
    }
    // eslint-disable-next-line no-console
    console.error(errorMessage);
}

export function handleError(error: APIError, fallbackMessage: string = 'Unknown Error') {
    const { response } = error;
    const formatMessage = intlProxy.formatMessage;

    // browser aborted API eg during page refresh
    if (error.message === 'Request aborted') {
        // eslint-disable-next-line no-console
        console.log('Request aborted ', error);
        return;
    }

    if (error.message.includes('timeout')) {
        showError(
            `${formatMessage({ id: 'Import.Timeout.Message' })}: ${formatMessage({
                id: 'Import.Timeout.Title',
            })}`
        );
        return;
    }

    if (!response) {
        showError(fallbackMessage);
        return;
    }

    const httpErrorCode = response.status;
    const { code, message } = response.data;
    const errorMessage = message ?? error.message ?? fallbackMessage;

    switch (httpErrorCode) {
        case 401: // Unauthorized
            if (
                error.response?.request.responseURL === getApiURL('PASSWORD_CHANGE') ||
                error.response?.request.responseURL === getApiURL('MASQUERADE')
            ) {
                showError(formatMessage({ id: 'error.InvalidPassword' }));
            } else {
                showError(formatMessage({ id: 'error.SessionExpired' }));
                cleanUpCredentials(storeDispatch);
                browserHistory.push(routeURLs.AUTH);
            }
            break;
        case 403: // Forbidden (Permission denied)
            browserHistory.push(routeURLs.PERMISSION_DENIED);
            break;
        case 0: // browser aborted API eg during page refresh
            // eslint-disable-next-line no-console
            console.log('browser aborted status code 0 ', errorMessage);
            break;
        default:
            showError(errorMessage);
    }

    // Error code handling
    // eslint-disable-next-line no-empty
    switch (code) {
    }
}
