import React, { useCallback, useEffect, useState } from 'react';
import { Select } from 'antd';
import { isEmpty } from 'lodash';
import dayjs from 'dayjs';
import FormattedMessage from '../../localization/FormatMessage';
import styles from './ButtonShare.styl';
import CopyLink from './CopyLink';
import { numberEnumToArray } from '../../utils/generalUtils';
import { getSharingURL } from '../../api/utilsService';
import { logShareUserAction } from '../../api/analyticsEventService';
import { ProductCode } from '../../types/proceq';
import analytics from '../../analytics/firebaseAnalytics';
import { ShareLinkAction } from '../../analytics/analyticsConstants';

enum Duration {
    '1Day' = 24 * 60,
    '2Days' = 2 * 24 * 60,
    '1Week' = 7 * 24 * 60,
    '2Weeks' = 14 * 24 * 60,
}

const durations = numberEnumToArray(Duration);

interface MeasurementCopyLinkProps {
    visible: boolean;
    onCancel: () => void;
    mIDs: string[];
    product: ProductCode;
}

const MeasurementCopyLink: React.FunctionComponent<MeasurementCopyLinkProps> = (props) => {
    const { visible, onCancel, mIDs, product } = props;
    const [duration, setDuration] = useState(Duration['2Weeks']);
    const [isLoading, setIsLoading] = useState(false);
    const handleOnSelect = useCallback((value) => setDuration(value), []);
    const [sharingURL, setSharingURL] = useState('');

    const handleGenerateURL = useCallback(async () => {
        if (isEmpty(mIDs)) {
            return;
        }
        try {
            setIsLoading(true);
            // Share function
            const response = await getSharingURL({ product, mIDs, tokenValidityMinutes: duration });

            if (typeof response?.url === 'string') {
                setSharingURL(response.url);
            }
        } finally {
            setIsLoading(false);
            logShareUserAction('url', mIDs.length);
        }
    }, [duration, mIDs, product]);

    useEffect(() => {
        if (visible) {
            handleGenerateURL();
        } else {
            setSharingURL('');
            setDuration(Duration['2Weeks']);
        }
    }, [handleGenerateURL, visible]);

    return (
        <CopyLink
            modalTitle="Proceq.TitleShareByUrl"
            visible={visible}
            onCancel={() => {
                onCancel();
                analytics.logShareLinkEvent(ShareLinkAction.done, product);
            }}
            modalBody={
                <>
                    <div>
                        <FormattedMessage id="Proceq.FormExpiresIn" />{' '}
                    </div>
                    <div>
                        <Select className={styles.duration_selection} onSelect={handleOnSelect} value={duration}>
                            {durations.map((value) => (
                                <Select.Option value={value} key={value}>
                                    {dayjs.duration(value, 'minute').humanize()}
                                </Select.Option>
                            ))}
                        </Select>
                    </div>
                </>
            }
            sharingURL={sharingURL}
            isLoading={isLoading}
            product={product}
        />
    );
};

export default MeasurementCopyLink;
