import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { ConfigProvider } from 'antd';
import enGB from 'antd/lib/locale/en_GB';
import zhCN from 'antd/lib/locale/zh_CN';
import jaJP from 'antd/lib/locale/ja_JP';
import koKR from 'antd/lib/locale/ko_KR';
import deDE from 'antd/lib/locale/de_DE';
import frFR from 'antd/lib/locale/fr_FR';
import itIT from 'antd/lib/locale/it_IT';
import ptPT from 'antd/lib/locale/pt_PT';
import ruRU from 'antd/lib/locale/ru_RU';
import esES from 'antd/lib/locale/es_ES';
import dayjs from 'dayjs';
import IntlProxy from './IntlProxy';
import { messagesLib, getDefaultLanguage, LanguageOptions } from './index';
import * as storageUtils from '../utils/storageUtils';
import { THEME_CONFIG } from '../styles/antdThemeConfig';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/zh-cn';
import 'dayjs/locale/ja';
import 'dayjs/locale/ko';
import 'dayjs/locale/de';
import 'dayjs/locale/fr';
import 'dayjs/locale/it';
import 'dayjs/locale/pt';
import 'dayjs/locale/ru';
import 'dayjs/locale/es';

interface LocalizationContextState {
    language: LanguageOptions;
    setLanguage: (language: LanguageOptions) => void;
}

export const LocalizationContext = React.createContext<LocalizationContextState>({
    language: getDefaultLanguage(),
    setLanguage: () => {},
});

function getConfigProviderLocale(language: LanguageOptions) {
    switch (language) {
        case 'en':
            return enGB;
        case 'zh':
            return zhCN;
        case 'ja':
            return jaJP;
        case 'ko':
            return koKR;
        case 'de':
            return deDE;
        case 'fr':
            return frFR;
        case 'it':
            return itIT;
        case 'pt':
            return ptPT;
        case 'ru':
            return ruRU;
        case 'es':
            return esES;
        default:
            return enGB;
    }
}

function getDayjsLocale(language: LanguageOptions) {
    switch (language) {
        case 'en':
            return 'en-gb';
        case 'zh':
        case 'ja':
        case 'ko':
        case 'de':
        case 'fr':
        case 'it':
        case 'pt':
        case 'ru':
        case 'es':
            return language;
        default:
            return 'en-gb';
    }
}

export function updateDayjsLocale(language: LanguageOptions) {
    dayjs.locale(getDayjsLocale(language));
}

interface LocalizedIntlProviderProps {
    defaultLanguage?: LanguageOptions;
}

const LocalizedIntlProvider: React.FunctionComponent<LocalizedIntlProviderProps> = (props) => {
    const [language, setLanguage] = useState(props.defaultLanguage ?? getDefaultLanguage());

    const handleSetLanguage = useCallback((myLanguage: LanguageOptions) => {
        setLanguage(myLanguage);
        updateDayjsLocale(myLanguage);
        storageUtils.saveLocalItem('language', myLanguage);
    }, []);

    const messages = useMemo(() => {
        return {
            ...messagesLib.en,
            ...messagesLib[language],
        };
    }, [language]);

    useEffect(() => {
        handleSetLanguage(language);
    }, [handleSetLanguage, language]);

    return (
        <LocalizationContext.Provider value={{ language, setLanguage: handleSetLanguage }}>
            <ConfigProvider
                theme={THEME_CONFIG}
                locale={getConfigProviderLocale(language)}
                button={{ autoInsertSpace: false }}
            >
                <IntlProvider locale={language} messages={messages}>
                    {props.children}
                    <IntlProxy />
                </IntlProvider>
            </ConfigProvider>
        </LocalizationContext.Provider>
    );
};

export default LocalizedIntlProvider;
