import React, { useCallback, useContext, useState } from 'react';
import { UserFilesContext } from './UserFilesContext';
import UserFileModals from './UserFileModals';
import { useUserFileExtensions, useUserFileList } from '../../../queries/userFileQueries';
import { useDataViewContext } from '../DataViewProvider';
import { normalizer } from '../../../utils/genericNormalizer';

export const UserFilesProvider: React.FunctionComponent = (props) => {
    const { dataType, product } = useDataViewContext();
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [orderBy, setOrderBy] = useState<string | undefined>();
    const [searchText, setSearchText] = useState<string | undefined>();
    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

    // modal related variables
    const [showRenameModal, setShowRenameModal] = useState<
        undefined | { id: string; currentName: string; extension: string }
    >();
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState<
        { ids: string[]; name?: string } | undefined
    >();

    const resetConfig = useCallback(() => {
        setPage(1);
        setOrderBy(undefined);
        setSearchText('');
    }, []);

    const fileListQueryParams = {
        offset: (page - 1) * pageSize,
        limit: pageSize,
        ...(searchText ? { q: searchText } : {}),
        orderBy,
    };

    const { isFetching, data } = useUserFileList({ ...fileListQueryParams, product });

    const { data: userFileExtensions = [] } = useUserFileExtensions(dataType, product);
    const { entities } = normalizer(userFileExtensions);

    return (
        <UserFilesContext.Provider
            value={{
                page,
                setPage,
                pageSize,
                setPageSize,
                orderBy,
                setOrderBy,
                searchText,
                setSearchText,
                resetConfig,
                isFetching,
                fileCount: data?.totalRecords ?? 0,
                userFiles: data?.data ?? [],
                showRenameModal,
                setShowRenameModal,
                selectedKeys,
                setSelectedKeys,
                showUploadModal,
                setShowUploadModal,
                fileExtensions: entities,
                showDeleteConfirmationModal,
                setShowDeleteConfirmationModal,
                fileListQueryParams,
            }}
        >
            {props.children}
            <UserFileModals />
        </UserFilesContext.Provider>
    );
};

export const useUserFilesContext = () => useContext(UserFilesContext);
