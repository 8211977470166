import React from 'react';
import { Popover } from 'antd';
import { ProductFeature, useProductContext } from '../../DataView/ProductContextProvider';
import { ToggleSwitch } from '../../DataView/ToggleSwitch';
import { FormatIDs } from '../../../types';

const CUSTOMISABLE_FLAGS: Partial<Record<ProductFeature, FormatIDs>> = {
    [ProductFeature.IGNORE_INVALID_MEASUREMENTS]: 'DevFeature.IgnoreInvalidMeasurements',
    [ProductFeature.INSIGHTS_RELAUNCH_APP]: 'Tool.Insights.RelaunchApp',
    [ProductFeature.SPATIAL_DATA]: 'DataView.Folder.SpatialData',
    [ProductFeature.PROFOMETER_DOCX]: 'DevFeature.ProfometerDocx',
    [ProductFeature.PROFOMETER_STATISTICS]: 'DevFeature.ProfometerStatistics',
};

const DevFeatureConfig: React.FunctionComponent = (props) => {
    const { toggleDevFeature, devFeatureConfigs } = useProductContext();
    const content = Object.keys(CUSTOMISABLE_FLAGS).map((flag) => {
        const productFeature = flag as ProductFeature;
        return (
            <ToggleSwitch
                key={flag}
                checked={devFeatureConfigs[productFeature] ?? false}
                onChange={(checked) => {
                    toggleDevFeature(productFeature, checked);
                }}
                title={CUSTOMISABLE_FLAGS[productFeature] ?? ' '}
            />
        );
    });

    return content.length > 0 ? (
        <Popover
            trigger={['click']}
            title={'Feature Flags'}
            content={<div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>{content}</div>}
        >
            {props.children}
        </Popover>
    ) : (
        <>{props.children}</>
    );
};

export default DevFeatureConfig;
